var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('PageTitle',{staticClass:"ft d-flex flex-row ml-2",attrs:{"text":"Customers"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 mr-3",attrs:{"fab":"","icon":"","loading":_vm.isListLoading || _vm.pageLoading,"small":"","color":"primary"},on:{"click":_vm.refreshPage}},on),[_c('i',{staticClass:"material-icons-outlined "},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Refresh")])]),_c('div',[_c('v-text-field',{staticClass:"mt-2 ft font-size-sm black--text",staticStyle:{"min-width":"500px"},attrs:{"dense":"","append-icon":"search","light":"","single-line":"","solo-inverted":"","placeholder":"Search by customer name  ...","hide-details":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-4"},[_c('v-chip-group',{attrs:{"show-arrows":"","multiple":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},_vm._l((_vm.branches),function(branch){return _c('v-chip',{key:branch.Id,staticClass:"box-shadow-light ft font-weight-bold",attrs:{"label":"","color":"white","value":branch.id,"active-class":"primary--text"}},[_c('span',{staticClass:"ft font-weight-bold "},[_vm._v(_vm._s(branch.name))])])}),1)],1),_c('div',[_c('h4',{staticClass:"blue-grey--text ft font-size-md text-uppercase mt-2",staticStyle:{"margin-left":"5px"}},[_vm._v(" List Of Business Customers ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"ft font-weight-medium box-shadow-light",attrs:{"items":_vm.filteredBusinesses,"headers":_vm.headers.business,"loading":_vm.isListLoading,"search":_vm.search,"options":_vm.options.businesses,"footer-props":{
          itemsPerPageOptions: [10, 20, 30]
        }},on:{"update:options":function($event){return _vm.$set(_vm.options, "businesses", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"small":"","label":""},on:{"click":function($event){return _vm.openMail(item)}}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.email))])])]}},{key:"item.directors",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.directors.length))])])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.amount, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" ))+" ")])])]}},{key:"item.branchId",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.branchId.name))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":_vm.dialog('details') || !item.form3Upload,"color":"primary","icon":""},on:{"click":function($event){return _vm.openPDF(item)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("picture_as_pdf")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("View Attached Docs")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":_vm.dialog('details'),"color":"primary","icon":""},on:{"click":function($event){return _vm.open('details', 'customers/details', {
                    id: item.id,
                    type: 'customer-business'
                  })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("assignment")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("View Customer Details")])])]}}])})],1)],1),_c('div',[_c('h4',{staticClass:"blue-grey--text ft font-size-md text-uppercase mt-2",staticStyle:{"margin-left":"5px"}},[_vm._v(" List Of Individual Customers ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"ft font-weight-medium box-shadow-light",attrs:{"items":_vm.filteredIndividuals,"loading":_vm.pageLoading,"headers":_vm.headers.individuals,"server-items-length":_vm.pagination.individuals.total,"search":_vm.search,"options":_vm.options.individuals,"footer-props":{
          itemsPerPageOptions: [10, 20, 30]
        }},on:{"update:options":function($event){return _vm.$set(_vm.options, "individuals", $event)}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"small":"","label":""},on:{"click":function($event){return _vm.openMail(item)}}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.email))])])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.amount, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" ))+" ")])])]}},{key:"item.branchId",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.branchId ? item.branchId.name : "No Branch"))])])]}}])})],1)],1),_c('div',[_c('h4',{staticClass:" font blue-grey--text text-uppercase",staticStyle:{"margin-top":"5px","margin-left":"10px"}},[_vm._v(" List Of Suppliers ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"ft font-weight-medium box-shadow-light",attrs:{"items":_vm.filteredSuppliers,"loading":_vm.isListLoading,"headers":_vm.headers.business.filter(
            function (header) { return header.value !== 'directors' &&
              header.value !== 'amount' &&
              header.value !== 'actions'; }
          ).concat( [{ text: 'Credit Purchases Amt.', value: 'creditPurchasesAmount' }],
          [{ text: 'Actions.', value: 'actions' }]
        ),"server-items-length":_vm.supplierPaginate.total,"search":_vm.search,"options":_vm.options.suppliers,"footer-props":{
          itemsPerPageOptions: [10, 20, 30]
        }},on:{"update:options":function($event){return _vm.$set(_vm.options, "suppliers", $event)}},scopedSlots:_vm._u([{key:"item.creditPurchasesAmount",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"primary","small":""}},[_c('span',{staticClass:" font font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.creditPurchasesAmount, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" )))])])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"small":"","label":""},on:{"click":function($event){return _vm.openMail(item)}}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.email))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":item.businessRegistrationCert === null,"color":"primary","icon":""},on:{"click":function($event){return _vm.openPDF({ form3Upload: item.businessRegistrationCert })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("picture_as_pdf")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("View attached docs")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":_vm.dialog('details'),"color":"primary","icon":""},on:{"click":function($event){return _vm.open('details', 'suppliers/details', {
                    id: item.id
                  })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("assignment")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("View Customer Details")])])]}}])})],1)],1),_c('DetailsDrawerDialog',{attrs:{"state":_vm.dialog('details'),"details":_vm.details},on:{"close":_vm.close,"openMail":_vm.openMail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }