<template>
  <v-container fluid>
    <div class="d-flex flex-row flex-grow-1">
      <PageTitle text="Customers" class="ft d-flex flex-row ml-2" />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="refreshPage"
            fab
            v-on="on"
            icon
            :loading="isListLoading || pageLoading"
            small
            class="mt-2 mr-3"
            color="primary"
            ><i class="material-icons-outlined ">refresh</i></v-btn
          >
        </template>
        <span class="ft font-weight-medium">Refresh</span>
      </v-tooltip>
      <div>
        <v-text-field
          dense
          append-icon="search"
          light
          single-line
          v-model="search"
          style="min-width: 500px;"
          solo-inverted
          placeholder="Search by customer name  ..."
          class="mt-2 ft font-size-sm black--text"
          hide-details
          flat
        />
      </div>
    </div>

    <div class="d-flex flex-row flex-grow-1 mt-4">
      <v-chip-group v-model="filters" show-arrows multiple>
        <v-chip
          v-for="branch in branches"
          :key="branch.Id"
          label
          class="box-shadow-light ft font-weight-bold"
          color="white"
          :value="branch.id"
          active-class="primary--text"
        >
          <span class="ft font-weight-bold ">{{ branch.name }}</span>
        </v-chip>
      </v-chip-group>
    </div>
    <div>
      <h4
        style=" margin-left: 5px"
        class="blue-grey--text ft font-size-md text-uppercase mt-2"
      >
        List Of Business Customers
      </h4>
    </div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="filteredBusinesses"
          :headers="headers.business"
          :loading="isListLoading"
          :search="search"
          :options.sync="options.businesses"
          class="ft font-weight-medium box-shadow-light"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30]
          }"
        >
          <template #item.name="{item}">
            <v-chip color="primary" small label>
              <span class="ft font-weight-medium">{{ item.name }}</span>
            </v-chip>
          </template>
          <template #item.email="{item}">
            <v-chip @click="openMail(item)" small label class="cursor-pointer">
              <span class="ft font-weight-medium">{{ item.email }}</span>
            </v-chip>
          </template>
          <template #item.directors="{item}">
            <v-chip color="primary" small label>
              <span class="ft font-weight-medium">{{
                item.directors.length
              }}</span>
            </v-chip>
          </template>
          <template #item.amount="{item}">
            <v-chip color="primary" small label>
              <span class=" ft font-weight-medium"
                >{{
                  item.amount
                    | currencyFormat(
                      currency && currency.length > 0 ? currency[0].code : "GHS"
                    )
                }}
              </span>
            </v-chip>
          </template>
          <template #item.branchId="{item}">
            <v-chip color="primary" small label>
              <span class="ft font-weight-medium">{{
                item.branchId.name
              }}</span>
            </v-chip>
          </template>
          <template #item.actions="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="openPDF(item)"
                  small
                  fab
                  :disabled="dialog('details') || !item.form3Upload"
                  color="primary"
                  icon
                >
                  <i class="material-icons-outlined font-size-sm"
                    >picture_as_pdf</i
                  >
                </v-btn>
              </template>
              <span class=" ft font-weight-medium">View Attached Docs</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    open('details', 'customers/details', {
                      id: item.id,
                      type: 'customer-business'
                    })
                  "
                  small
                  fab
                  :disabled="dialog('details')"
                  color="primary"
                  icon
                >
                  <i class="material-icons-outlined font-size-sm">assignment</i>
                </v-btn>
              </template>
              <span class=" ft font-weight-medium">View Customer Details</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div>
      <h4
        style=" margin-left: 5px"
        class="blue-grey--text ft font-size-md text-uppercase mt-2"
      >
        List Of Individual Customers
      </h4>
    </div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="filteredIndividuals"
          :loading="pageLoading"
          :headers="headers.individuals"
          :server-items-length="pagination.individuals.total"
          :search="search"
          :options.sync="options.individuals"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30]
          }"
          class="ft font-weight-medium box-shadow-light"
        >
          <template #item.email="{item}">
            <v-chip @click="openMail(item)" small label class="cursor-pointer">
              <span class="ft font-weight-medium">{{ item.email }}</span>
            </v-chip>
          </template>
          <template #item.amount="{item}">
            <v-chip color="primary" small label>
              <span class=" ft font-weight-medium"
                >{{
                  item.amount
                    | currencyFormat(
                      currency && currency.length > 0 ? currency[0].code : "GHS"
                    )
                }}
              </span>
            </v-chip>
          </template>
          <template #item.branchId="{item}">
            <v-chip color="primary" small label class="cursor-pointer">
              <span class="ft font-weight-medium">{{
                item.branchId ? item.branchId.name : "No Branch"
              }}</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div>
      <h4
        style="margin-top: 5px; margin-left: 10px"
        class=" font blue-grey--text text-uppercase"
      >
        List Of Suppliers
      </h4>
    </div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="filteredSuppliers"
          :loading="isListLoading"
          :headers="[
            ...headers.business.filter(
              header =>
                header.value !== 'directors' &&
                header.value !== 'amount' &&
                header.value !== 'actions'
            ),
            { text: 'Credit Purchases Amt.', value: 'creditPurchasesAmount' },
            { text: 'Actions.', value: 'actions' }
          ]"
          :server-items-length="supplierPaginate.total"
          :search="search"
          :options.sync="options.suppliers"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30]
          }"
          class="ft font-weight-medium box-shadow-light"
        >
          <template #item.creditPurchasesAmount="{item}">
            <v-chip label color="primary" small>
              <span class=" font font-weight-medium">{{
                item.creditPurchasesAmount
                  | currencyFormat(
                    currency && currency.length > 0 ? currency[0].code : "GHS"
                  )
              }}</span>
            </v-chip>
          </template>
          <template #item.email="{item}">
            <v-chip @click="openMail(item)" small label class="cursor-pointer">
              <span class="ft font-weight-medium">{{ item.email }}</span>
            </v-chip>
          </template>
          <template #item.actions="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    openPDF({ form3Upload: item.businessRegistrationCert })
                  "
                  small
                  fab
                  :disabled="item.businessRegistrationCert === null"
                  color="primary"
                  icon
                >
                  <i class="material-icons-outlined font-size-sm"
                    >picture_as_pdf</i
                  >
                </v-btn>
              </template>
              <span class=" ft font-weight-medium">View attached docs</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    open('details', 'suppliers/details', {
                      id: item.id
                    })
                  "
                  small
                  fab
                  :disabled="dialog('details')"
                  color="primary"
                  icon
                >
                  <i class="material-icons-outlined font-size-sm">assignment</i>
                </v-btn>
              </template>
              <span class=" ft font-weight-medium">View Customer Details</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DetailsDrawerDialog
      :state="dialog('details')"
      @close="close"
      :details="details"
      @openMail="openMail"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import DialogMixins from "../../../mixins/DialogMixins";
import { currencyFormat } from "../../../filters/currencyFormat";
export default {
  name: "Index",
  components: {
    PageTitle: () => import(`../../../components/PageTitle`),
    DetailsDrawerDialog: () => import(`./dialogs/DetailsDrawerDialog`)
  },
  mixins: [DialogMixins],
  data() {
    return {
      headers: {
        business: [
          {
            text: "Reg. No.",
            value: "businessRegistrationNumber"
          },
          {
            text: " Name",
            value: "name"
          },
          {
            text: " Email",
            value: "email"
          },

          {
            text: "Contact",
            value: "contact"
          },
          {
            text: "No. Of Directors",
            value: "directors"
          },
          {
            text: "Bal./Amount",
            value: "amount"
          },
          {
            text: "Actions",
            value: "actions"
          }
        ],
        individuals: [
          {
            text: "Name",
            value: "name"
          },
          {
            text: " Email",
            value: "email"
          },

          {
            text: "Contact",
            value: "phone"
          },
          {
            text: "Bal./Amount",
            value: "amount"
          },
          {
            text: "Residential Address",
            value: "residentAddress"
          }
        ]
      },
      search: "",
      filters: [],
      options: {
        businesses: {},
        individuals: {},
        suppliers: {}
      }
    };
  },
  filters: {
    currencyFormat
  },
  computed: {
    ...mapGetters({
      branches: "orders/getAllBranches",
      individuals: "customers/getIndividualCustomers",
      businesses: "customers/getBusinessCustomers",
      isListLoading: "getIsListLoading",
      pageLoading: "getIsPageLoading",
      details: "customers/getCustomerDetails",
      pagination: "customers/getPagination",
      suppliers: "suppliers/getListOfSuppliers",
      supplierPaginate: "suppliers/getPagination",
      currency: "settings/getSystemCurrency"
    }),

    filteredIndividuals() {
      return this.individuals.filter(individual => {
        return (
          individual?.name?.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
        );
      });
    },
    filteredBusinesses() {
      return this.businesses.filter(business => {
        return (
          business?.name?.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
        );
      });
    },
    filteredSuppliers() {
      return this.suppliers.filter(supplier => {
        return (
          supplier?.name?.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
        );
      });
    }
  },
  watch: {
    filters(value) {
      this.fetchResultsWithFilters(value);
    },
    "options.businesses"(payload) {
      const { itemsPerPage, page } = payload;
      this.$store.dispatch("customers/businesses", {
        page: page,
        limit: itemsPerPage,
        filters: this.filters?.join(",")
      });
    },
    "options.individuals"(payload) {
      const { itemsPerPage, page } = payload;
      this.$store.dispatch("customers/individuals", {
        page: page,
        limit: itemsPerPage,
        filters: this.filters?.join(",")
      });
    },
    "options.suppliers"(payload) {
      const { itemsPerPage, page } = payload;
      this.$store.dispatch("suppliers/list", {
        isPaginated: true,
        page,
        limit: itemsPerPage,
        filters: this.filters?.join(",")
      });
    }
  },
  methods: {
    openURL(url) {
      window.open(url, "_blank");
      window.focus();
    },
    openPDF(pdf) {
      if (pdf?.form3Upload) {
        const url = `${process.env.VUE_APP_BASE.replace(
          process.env.NODE_ENV === "production" ? "/v1" : "/api/v1",
          ""
        )}/static/${pdf.form3Upload}`;
        this.openURL(url);
      }
    },
    openMail({ email }) {
      this.openURL(`mailto:${email}`);
    },
    refreshPage() {
      this.fetchResultsWithFilters(this.filters);
    },
    fetchResultsWithFilters(filters) {
      const { businesses, individuals } = this.pagination;
      this.$store.dispatch("customers/businesses", {
        page: businesses.page,
        limit: businesses.limit,
        filters: filters?.join(",")
      });
      this.$store.dispatch("customers/individuals", {
        page: individuals.page,
        limit: individuals.limit,
        filters: filters?.join(",")
      });
      this.$store.dispatch("suppliers/list", {
        isPaginated: true,
        page: this.supplierPaginate.page,
        limit: this.supplierPaginate.limit,
        filters: filters?.join(",")
      });
    }
  },
  created() {
    let payload = {
      page: 1,
      limit: 2,
      filters: [].join(",")
    };
    this.$store.dispatch("orders/branchList");
    this.$store.dispatch("customers/businesses", payload);
    this.$store.dispatch("customers/individuals", payload);
    this.$store.dispatch("settings/systemCurrency");
    this.$store.dispatch("suppliers/list", { isPaginated: true, ...payload });
  }
};
</script>
